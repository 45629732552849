import {EmailSettingNames} from '../constants/EmailNotificationConstants';
import {MailTemplates} from '../shared';
import {DocumentBase} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Setting = DocumentBase;

/**
 * Chargeback email notification job settings
 */
export type ChargebackEmailNotificationJobSetting = Setting & {
  lastChargeBackTransactionsId?: number;
};

export type ChargebackEmailNotificationJobSettingView =
  ViewBase<ChargebackEmailNotificationJobSetting>;

export const selectChargebackEmailNotificationJobSettingView: ViewSelector<
  ChargebackEmailNotificationJobSettingView,
  ChargebackEmailNotificationJobSetting
> = snapshot => {
  const setting: ChargebackEmailNotificationJobSettingView = {
    ...defaultViewSelector(snapshot),
  };
  return setting;
};

/**
 * MFA settings
 */
export type MFASettings = Setting & {
  isEnabledForERPUsers?: boolean;
};
export type MFASettingsView = ViewBase<MFASettings>;
export const selectMFASettingsView: ViewSelector<MFASettingsView, MFASettings> = snapshot => {
  const setting: MFASettingsView = {
    ...defaultViewSelector(snapshot),
  };
  return setting;
};

/**
 * Email settings
 */
export type EmailSettings = Setting & {
  templatesBlackList: MailTemplates[];
  templatesWhiteList: MailTemplates[];
};

export type EmailSettingsItem = Setting & {
  from?: string;
  to?: string[];
  bcc?: string[];
  name: EmailSettingNames; // unique name of email
  isEnabled: boolean;
};
export type EmailSettingsItemView = ViewBase<EmailSettingsItem>;
export const selectEmailSettingsItemView: ViewSelector<
  EmailSettingsItemView,
  EmailSettingsItem
> = snapshot => {
  const setting: EmailSettingsItemView = {
    ...defaultViewSelector(snapshot),
  };
  return setting;
};

export type EmailSettingsView = ViewBase<EmailSettings>;
export const selectEmailSettingsView: ViewSelector<EmailSettingsView, EmailSettings> = snapshot => {
  const setting: EmailSettingsView = {
    ...defaultViewSelector(snapshot),
  };
  return setting;
};

/**
 * Constants
 */
export const MFASettingsDocId = 'MFASettings';
export const ChargebackEmailNotificationJobSettingDocId = 'ChargebackEmailNotificationJobSetting';
export const EmailSettingsDocId = 'EmailSettings';
