import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Collections, Firebase, StoragePath} from '@ozark/common';
import {GroupForm, GroupFormData, GroupFormSchema} from '@ozark/common/components';
import firebase from 'firebase/compat/app';
import {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNotification} from '../../hooks/useNotification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const CreateDialog = ({onClose}: {onClose: () => void}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const showNotification = useNotification();

  const hookForm = useForm<GroupFormData>({
    resolver: yupResolver(GroupFormSchema),
  });

  const {handleSubmit} = hookForm;

  const upload = (path: string, file: File) => {
    return new Promise((resolve, reject) => {
      const task = Firebase.storage.ref(`${path}/${file.name}`).put(file);
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(`uploading: ${progress}`);
        },
        (err: any) => {
          console.error(err);
          reject(err);
        },
        () => {
          Firebase.storage
            .ref(path)
            .child(file.name)
            .getDownloadURL()
            .then(url => {
              resolve(url);
            });
        }
      );
    });
  };

  const onSuccess: SubmitHandler<GroupFormData> = async data => {
    try {
      setLoading(true);
      Promise.all([
        upload(StoragePath.groups, data.image!.file),
        data.background ? upload(StoragePath.groups, data.background.file) : undefined,
      ]).then(async ([logoUrl, backgroundUrl]) => {
        delete data.image;

        if (backgroundUrl) {
          delete data.background;
        }
        data.termsUrl = data.termsUrl?.trim();

        if (data?.applicationSettings?.splitAdditionalServiceFee === undefined) {
          data = {
            ...data,
            applicationSettings: {
              ...data?.applicationSettings,
              splitAdditionalServiceFee: true,
            },
          };
        }

        await Firebase.firestore.collection(Collections.groups).add({
          ...data,
          logoUrl,
          backgroundUrl,
          isActive: true,
          createdAt: new Date(),
        });
        setLoading(false);
        onClose();
      });
    } catch (_err) {
      showNotification('error', 'Failed to create group.');
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`error saving group ${JSON.stringify(data)}`);
    setLoading(false);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Create Group</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container spacing={1}>
            <GroupForm isAuthUserAdmin={true} hookForm={hookForm} />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
