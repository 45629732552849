import {Stack, Typography} from '@mui/material';

interface Props {
  caption: string;
  value: string | JSX.Element;
}
export const RiskTextInfo = ({caption, value}: Props) => {
  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="body1">{caption}</Typography>
      <Typography variant="body1" sx={{fontWeight: '500'}}>
        {value}
      </Typography>
    </Stack>
  );
};
