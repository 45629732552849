import WarningIcon from '@mui/icons-material/Warning';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {blueGrey} from '@mui/material/colors';
import {
  AccountStatus,
  ApplicationAchDelayOptions,
  ApplicationView,
  ReservePercentDisplayOptions,
  TerminatedSubCategories,
  useApiContainer,
} from '@ozark/common';
import {CancelOperationMessage} from '@ozark/common/api/Constants';
import {currentFormatter} from '@ozark/common/components';
import {sumBy} from '@s-libs/micro-dash';
import {CancelTokenSource} from 'axios';
import {format} from 'date-fns-tz';
import {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {EligibleReleaseDateEditor} from './EligibleReleaseDateEditor';
import {RiskApplicationAssociations} from './RiskApplicationAssociations';
import {RiskTextInfo} from './RiskTextInfo';
import {ApplicationRiskInfoFormModel} from './types';
import {useRiskModuleIsReadOnly, useRiskSection} from './useRiskSection';

interface Props {
  application: ApplicationView;
}

const getFormattedOrLoading = (numberStr: number | null | undefined) => {
  if (numberStr === undefined) {
    return <CircularProgress sx={{height: '20px!important', width: '20px!important'}} />;
  }
  if (numberStr === null) {
    return 'N/A';
  }
  return currentFormatter.format(numberStr);
};

export const RiskSection = ({application}: Props) => {
  const [reserveBalance, setReserveBalance] = useState<number | null | undefined>();
  const hookForm = useForm<ApplicationRiskInfoFormModel>({
    defaultValues: {
      accountStatus: application.accountStatus?.status,
      accountStatusSubCategory: application.accountStatus?.subCategory,
      riskStatus: application.riskStatus,
      riskLabels: application.riskLabels,
      eligibleReleaseDate: application.accountStatus?.eligibleReleaseDate?.toDate(),
    },
  });

  const api = useApiContainer();
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const {
    formState: {isDirty},
    handleSubmit,
    control,
    watch,
  } = hookForm;

  const isReadOnly = useRiskModuleIsReadOnly();
  const {
    loading,
    handleSaveChanges,
    handleAccountStatusChanged,
    riskLabelDictionaryValues,
    allRiskStatuses,
  } = useRiskSection(application, hookForm);

  const watchAccountStatus = watch('accountStatus');
  const isAccountStatusTerminated = watchAccountStatus === AccountStatus.terminated;
  const reservePercentDisplayOption = ReservePercentDisplayOptions.find(
    x => x.reservePercentOption === application.reservePercentOption
  );
  const applicationAchDelay = ApplicationAchDelayOptions.find(
    x => x.applicationAchDelay === application.applicationAchDelay
  );

  const getReserves = useCallback(
    (cancelSource: CancelTokenSource | undefined) => {
      api?.reserves
        .getReserveActivity(application.mid, undefined, cancelSource?.token)
        .then(reserves => {
          if (!reserves || !reserves.length) {
            setReserveBalance(null);
            return;
          }
          const reservesDisplayed = reserves.filter(x => x.isDisplayed);
          const totalReservesDisplayed = sumBy(reservesDisplayed || [], e =>
            parseFloat(e.reserveAmountUpdated || e.reserveAmount || '0')
          );
          const totalReleasesDisplayed = sumBy(reservesDisplayed || [], e =>
            parseFloat(e.releaseAmountUpdated || e.releaseAmount || '0')
          );
          setReserveBalance(totalReservesDisplayed - totalReleasesDisplayed);
        });
    },
    [api?.reserves, application.mid]
  );

  const cancelRequests = useCallback(() => {
    const cancelSource = api?.reserves.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });
    return cancelSource;
  }, [api?.reserves]);

  useEffect(() => {
    console.log('1111');
    const cancelRequestToken = cancelRequests();
    getReserves(cancelRequestToken);
  }, [cancelRequests, getReserves]);

  return (
    <>
      <Paper
        sx={{
          padding: theme => theme.spacing(1, 2, 3),
          marginBottom: 2,
          position: 'relative',
        }}
        square
      >
        <Grid
          container
          rowSpacing={{md: 1, xs: 2.5}}
          columnSpacing={2}
          direction="row"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Box sx={{mb: 0}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: blueGrey[700],
                    py: 1,
                  }}
                  variant="body1"
                  gutterBottom
                >
                  <WarningIcon sx={{ml: 1, mr: 2}} /> Risk Info
                </Typography>
                {isDirty && (
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    size="small"
                    onClick={handleSubmit(handleSaveChanges)}
                  >
                    Save changes
                  </LoadingButton>
                )}
              </Box>

              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{pr: {md: 2}}}>
            <Controller
              name="accountStatus"
              control={control}
              render={({field: {onChange, ...props}}) => (
                <Autocomplete
                  options={Object.values(AccountStatus)}
                  onChange={(_e, value) => {
                    handleAccountStatusChanged();
                    onChange(value);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Account Status" variant="standard" />
                  )}
                  disabled={isReadOnly}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{pr: {md: 1}}}>
            <Controller
              name="accountStatusSubCategory"
              control={control}
              render={({field: {value, onChange, ...props}}) => (
                <Autocomplete
                  options={Object.values(TerminatedSubCategories)}
                  disabled={isReadOnly || !isAccountStatusTerminated}
                  value={value ?? null}
                  onChange={(_e, value) => onChange(value)}
                  renderInput={params => (
                    <TextField {...params} label="Account Sub Category" variant="standard" />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{pl: {md: 1}}}>
              <Controller
                name="eligibleReleaseDate"
                control={control}
                render={({field: {value, onChange}}) => (
                  <TextField
                    label="Reserve Eligible Release Date"
                    variant="standard"
                    fullWidth
                    value={value ? format(value, 'MM/dd/yyyy') : ''}
                    disabled
                    InputLabelProps={{
                      sx: {
                        paddingRight: '50px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EligibleReleaseDateEditor
                            eligibleReleaseDate={value}
                            handleSave={onChange}
                            allowEdit={!isReadOnly && isAccountStatusTerminated}
                          />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{pr: {md: 2}}}>
            <Controller
              name="riskStatus"
              control={control}
              render={({field: {value, onChange, ...props}}) => (
                <Autocomplete
                  options={allRiskStatuses}
                  value={value ?? null}
                  onChange={(_e, value) => onChange(value)}
                  renderInput={params => (
                    <TextField {...params} label="Risk Status" variant="standard" />
                  )}
                  disabled={isReadOnly}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="riskLabels"
              control={control}
              render={({field: {onChange, ...props}}) => (
                <Autocomplete
                  multiple
                  size="small"
                  freeSolo
                  options={riskLabelDictionaryValues.data ?? []}
                  sx={{
                    '&.MuiAutocomplete-root .MuiInput-root.MuiInputBase-sizeSmall': {
                      marginTop: '19px',
                    },
                  }}
                  onChange={(_e, values: string[]) => onChange(values)}
                  renderInput={params => (
                    <TextField {...params} label="Risk Labels" variant="standard" />
                  )}
                  disabled={isReadOnly}
                  disableCloseOnSelect
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{marginTop: '20px'}}>
            <RiskTextInfo caption="Reserve:" value={reservePercentDisplayOption?.text ?? 'N/A'} />
            <RiskTextInfo
              caption="Reserve Cap:"
              value={getFormattedOrLoading(application.reserveCapped ?? null)}
            />
            <RiskTextInfo
              caption="Upfront Reserve:"
              value={getFormattedOrLoading(application.reserveUpfront ?? null)}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{marginTop: '20px'}}>
            <RiskTextInfo
              caption="Reserve Balance:"
              value={getFormattedOrLoading(reserveBalance)}
            />
            <RiskTextInfo caption="ACH Delay:" value={applicationAchDelay?.text ?? 'N/A'} />
          </Grid>
        </Grid>
      </Paper>

      {application && (
        <RiskApplicationAssociations application={application} readOnly={isReadOnly} />
      )}
    </>
  );
};
